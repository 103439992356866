$( document ).ready(function() {

  /////Slider on Card Grid release on mobile
	var $slick_slider = $('.card-grid-container');
	
	var standard_settings = {
		dots: true,
		arrows: true 
	  }
	  
	  var center_variable_settings = { 
		  dots: true,
		  arrows: true,
		  centerMode: true,
		  slidesToShow: 1,
		  variableWidth: true
	  }
  
	  var center_padding_settings = {
		  dots: true,
		  arrows: true,
		  centerMode: true,
		  centerPadding: '30px'
	  }

	function slick_on_mobile2(slider, settings){
	//Variation on Slick On Mobile that does a different mobile check
	  $(window).on('load resize', function() {
		if ( slider.css('margin-left') !== "0px" ) {
		  if (slider.hasClass('slick-initialized')) {
			slider.slick('unslick');
		  }
		  return
		}
		if (!slider.hasClass('slick-initialized')) {
		  return slider.slick(settings);
		}
	  });
	}; 

slick_on_mobile( $( '.promo-grid-container, .referring-vets-container' ), center_padding_settings );
slick_on_mobile( $( '.services-list-card-grid-container' ), standard_settings );
slick_on_mobile( $( '.teams-card-grid-container' ), standard_settings );
slick_on_mobile( $( '.leadership-card-grid-container' ), standard_settings );
slick_on_mobile2( $( '.photo-gallery-row' ), center_variable_settings );

}); ///End Document Ready
