$(document).ready(function(){
	
	$( '.hero-carousel-init, .image-gallery-carousel' ).slick(
		{
			dots: true,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 6000
		}
	);

	$('.theme3 .testimonial-grid-carousel').slick(
		{
			centerMode: true,
			variableWidth: true,
			slidesToShow: 1,
			arrows: true,
			dots: true
		}
	);

	$( '.social-reviews-carousel' ).slick(
		{
			slidesToShow: 3,
			slidesToScroll: 3,
			// centerPadding: "20px",
			variableWidth: false,
			centerMode: false,
			arrows: true,
			dots: true,
			responsive: [
				{
				  breakpoint: 400,
				  settings: {
					variableWidth: false,
					centerMode: false,
					slidesToScroll: 1,
					slidesToShow: 1,
					dots: true,
					arrows: true
				  }
				},
				{
					breakpoint: 1280,
					settings: {
						variableWidth: true,
						centerMode: true,
						arrows: true,
						dots: true,
					}
				}
				
			]
		} 
	);

}); //document ready end
